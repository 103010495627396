:root {
  --cache-version: 1;
}

@media (max-width: 767px) {
  :root {
    --cache-version: 1;
  }
}

@media (min-width: 768px) and (max-width: 1079px) {
  :root {
    --cache-version: 1;
  }
}

@media (min-width: 1080px) {
  :root {
    --cache-version: 1;
  }
}

:root {
  --colors-brand-primary-25: #fff5f6;
  --colors-brand-primary-50: #feebec;
  --colors-brand-primary-100: #fed7da;
  --colors-brand-primary-200: #fdb0b5;
  --colors-brand-primary-300: #fc8d94;
  --colors-brand-primary-400: #fb656f;
  --colors-brand-primary-500: #fa3d4a;
  --colors-brand-primary-600: #f91625;
  --colors-brand-primary-700: #e30613;
  --colors-brand-primary-800: #95040e;
  --colors-brand-primary-900: #4b0207;
  --colors-brand-primary-950: #280104;
  --colors-brand-primary-hover-25: #ffdbdf;
  --colors-brand-primary-hover-50: #fdd3d5;
  --colors-brand-primary-hover-100: #fdbec3;
  --colors-brand-primary-hover-200: #fc979d;
  --colors-brand-primary-hover-300: #fb747c;
  --colors-brand-primary-hover-400: #fa4c58;
  --colors-brand-primary-hover-500: #f92433;
  --colors-brand-primary-hover-600: #ef0616;
  --colors-brand-primary-hover-700: #f90c19;
  --colors-brand-primary-hover-800: #ae0510;
  --colors-brand-primary-hover-900: #630309;
  --colors-brand-primary-hover-950: #410206;
  --colors-brand-premium-50: #feece7;
  --colors-brand-premium-100: #fddace;
  --colors-brand-premium-200: #fab198;
  --colors-brand-premium-300: #f88c68;
  --colors-brand-premium-400: #f66533;
  --colors-brand-premium-500: #ef440b;
  --colors-brand-premium-600: #be3608;
  --colors-brand-premium-700: #8e2806;
  --colors-brand-premium-800: #5d1a04;
  --colors-brand-premium-900: #310e02;
  --colors-brand-premium-950: #180701;
  --colors-system-neutral-25: #f7f7f8;
  --colors-system-neutral-50: #f1f1f3;
  --colors-system-neutral-100: #e4e4e7;
  --colors-system-neutral-200: #c9c9cf;
  --colors-system-neutral-300: #aeaeb7;
  --colors-system-neutral-400: #93939f;
  --colors-system-neutral-500: #787887;
  --colors-system-neutral-600: #60606c;
  --colors-system-neutral-700: #484851;
  --colors-system-neutral-800: #303036;
  --colors-system-neutral-900: #18181b;
  --colors-system-neutral-950: #0c0c0e;
  --colors-system-neutral-hover-25: #e9e9ec;
  --colors-system-neutral-hover-50: #e4e4e7;
  --colors-system-neutral-hover-100: #d6d6db;
  --colors-system-neutral-hover-200: #bcbcc3;
  --colors-system-neutral-hover-300: #a0a0ab;
  --colors-system-neutral-hover-400: #858593;
  --colors-system-neutral-hover-500: #6c6c7a;
  --colors-system-neutral-hover-600: #54545f;
  --colors-system-neutral-hover-700: #54545f;
  --colors-system-neutral-hover-800: #3c3c43;
  --colors-system-neutral-hover-900: #242429;
  --colors-system-neutral-hover-950: #18181b;
  --colors-system-red-50: #fef1f1;
  --colors-system-red-100: #fcdede;
  --colors-system-red-200: #fac2c2;
  --colors-system-red-300: #f7a1a1;
  --colors-system-red-400: #f48080;
  --colors-system-red-500: #f26464;
  --colors-system-red-600: #ef4444;
  --colors-system-red-700: #d31212;
  --colors-system-red-800: #8d0c0c;
  --colors-system-red-900: #460606;
  --colors-system-red-950: #260303;
  --colors-system-yellow-50: #fffae5;
  --colors-system-yellow-100: #fff5cc;
  --colors-system-yellow-200: #ffeb99;
  --colors-system-yellow-300: #ffe066;
  --colors-system-yellow-400: #ffd633;
  --colors-system-yellow-500: #fc0;
  --colors-system-yellow-600: #cca300;
  --colors-system-yellow-700: #997a00;
  --colors-system-yellow-800: #665200;
  --colors-system-yellow-900: #332900;
  --colors-system-yellow-950: #191400;
  --colors-system-green-50: #e6feec;
  --colors-system-green-100: #c9fdd6;
  --colors-system-green-200: #92fcad;
  --colors-system-green-300: #61fa87;
  --colors-system-green-400: #2af85e;
  --colors-system-green-500: #07e33d;
  --colors-system-green-600: #06b732;
  --colors-system-green-700: #048b26;
  --colors-system-green-800: #035918;
  --colors-system-green-900: #012d0c;
  --colors-system-green-950: #011907;
  --colors-system-blue-50: #ebefff;
  --colors-system-blue-100: #d6deff;
  --colors-system-blue-200: #adbeff;
  --colors-system-blue-300: #859dff;
  --colors-system-blue-400: #5c7cff;
  --colors-system-blue-500: #305bff;
  --colors-system-blue-600: #0031f5;
  --colors-system-blue-700: #0025b8;
  --colors-system-blue-800: #00187a;
  --colors-system-blue-900: #000c3d;
  --colors-system-blue-950: #00061f;
  --colors-system-purple-50: #fdebff;
  --colors-system-purple-100: #fad6ff;
  --colors-system-purple-200: #f5a8ff;
  --colors-system-purple-300: #f080ff;
  --colors-system-purple-400: #eb52ff;
  --colors-system-purple-500: #e728ff;
  --colors-system-purple-600: #cf00eb;
  --colors-system-purple-700: #9e00b3;
  --colors-system-purple-800: #680075;
  --colors-system-purple-900: #36003d;
  --colors-system-purple-950: #1b001f;
  --colors-alpha-primary-0: rgba(227, 6, 19, 0);
  --colors-alpha-primary-25: rgba(227, 6, 19, .024);
  --colors-alpha-primary-50: rgba(227, 6, 19, .05);
  --colors-alpha-primary-100: rgba(227, 6, 19, .1);
  --colors-alpha-primary-200: rgba(227, 6, 19, .2);
  --colors-alpha-primary-300: rgba(227, 6, 19, .3);
  --colors-alpha-primary-400: rgba(227, 6, 19, .4);
  --colors-alpha-primary-500: rgba(227, 6, 19, .5);
  --colors-alpha-primary-600: rgba(227, 6, 19, .6);
  --colors-alpha-primary-700: rgba(227, 6, 19, .698);
  --colors-alpha-primary-800: rgba(227, 6, 19, .8);
  --colors-alpha-primary-900: rgba(227, 6, 19, .898);
  --colors-alpha-primary-950: rgba(227, 6, 19, .95);
  --colors-alpha-primary-1000: #e30613;
  --colors-alpha-white-0: rgba(255, 255, 255, 0);
  --colors-alpha-white-25: rgba(255, 255, 255, .024);
  --colors-alpha-white-50: rgba(255, 255, 255, .05);
  --colors-alpha-white-100: rgba(255, 255, 255, .1);
  --colors-alpha-white-200: rgba(255, 255, 255, .2);
  --colors-alpha-white-300: rgba(255, 255, 255, .3);
  --colors-alpha-white-400: rgba(255, 255, 255, .4);
  --colors-alpha-white-500: rgba(255, 255, 255, .5);
  --colors-alpha-white-600: rgba(255, 255, 255, .6);
  --colors-alpha-white-700: rgba(255, 255, 255, .698);
  --colors-alpha-white-800: rgba(255, 255, 255, .8);
  --colors-alpha-white-900: rgba(255, 255, 255, .898);
  --colors-alpha-white-950: rgba(255, 255, 255, .95);
  --colors-alpha-white-1000: #fff;
  --colors-alpha-neutral-0: rgba(15, 23, 31, 0);
  --colors-alpha-neutral-25: rgba(15, 23, 31, .024);
  --colors-alpha-neutral-50: rgba(15, 23, 31, .05);
  --colors-alpha-neutral-100: rgba(15, 23, 31, .1);
  --colors-alpha-neutral-200: rgba(15, 23, 31, .2);
  --colors-alpha-neutral-300: rgba(15, 23, 31, .3);
  --colors-alpha-neutral-400: rgba(15, 23, 31, .4);
  --colors-alpha-neutral-500: rgba(15, 23, 31, .5);
  --colors-alpha-neutral-600: rgba(15, 23, 31, .6);
  --colors-alpha-neutral-700: rgba(15, 23, 31, .698);
  --colors-alpha-neutral-800: rgba(15, 23, 31, .8);
  --colors-alpha-neutral-900: rgba(15, 23, 31, .898);
  --colors-alpha-neutral-950: rgba(15, 23, 31, .95);
  --colors-alpha-neutral-1000: #0f171f;
  --colors-neutral-25: #f8fafc;
  --spacing-0: 0;
  --spacing-1: 1px;
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-6: 6px;
  --spacing-8: 8px;
  --spacing-10: 10px;
  --spacing-12: 12px;
  --spacing-14: 14px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-28: 28px;
  --spacing-32: 32px;
  --spacing-36: 36px;
  --spacing-40: 40px;
  --spacing-44: 44px;
  --spacing-48: 48px;
  --spacing-56: 56px;
  --spacing-64: 64px;
  --spacing-80: 80px;
  --spacing-96: 96px;
  --spacing-112: 112px;
  --spacing-128: 128px;
  --spacing-144: 144px;
  --spacing-160: 160px;
  --spacing-176: 176px;
  --spacing-192: 192px;
  --spacing-208: 208px;
  --spacing-224: 224px;
  --spacing-240: 240px;
  --spacing-256: 256px;
  --spacing-288: 288px;
  --spacing-320: 320px;
  --spacing-384: 384px;
  --spacing-724: 724px;
  --spacing-1080: 1080px;
  --radius-0: 0;
  --radius-2: 2px;
  --radius-4: 4px;
  --radius-6: 6px;
  --radius-8: 8px;
  --radius-12: 12px;
  --radius-16: 16px;
  --radius-24: 24px;
  --radius-full: 9999px;
  --icon-stroke-1: 1px;
  --icon-stroke-16: 1.33px;
  --icon-stroke-20: 1.67px;
  --icon-stroke-24: 2px;
  --icon-stroke-32: 2.67px;
  --icon-size-16: 16px;
  --icon-size-18: 18px;
  --icon-size-20: 20px;
  --icon-size-22: 22px;
  --icon-size-24: 24px;
  --icon-size-32: 32px;
  --typography-family-default: inter;
  --typography-family-alternative: barlow condensed;
  --typography-weight-regular: 400;
  --typography-weight-medium: 500;
  --typography-weight-semibold: 600;
  --typography-weight-bold: 700;
  --typography-weight-extrabold: 800;
  --typography-weight-black: 900;
  --typography-scale-6: 6px;
  --typography-scale-8: 8px;
  --typography-scale-9: 9px;
  --typography-scale-10: 10px;
  --typography-scale-11: 11px;
  --typography-scale-12: 12px;
  --typography-scale-13: 13px;
  --typography-scale-14: 14px;
  --typography-scale-15: 15px;
  --typography-scale-16: 16px;
  --typography-scale-17: 17px;
  --typography-scale-18: 18px;
  --typography-scale-20: 20px;
  --typography-scale-22: 22px;
  --typography-scale-24: 24px;
  --typography-scale-26: 26px;
  --typography-scale-28: 28px;
  --typography-scale-30: 30px;
  --typography-scale-32: 32px;
  --typography-scale-34: 34px;
  --typography-scale-36: 36px;
  --typography-scale-40: 40px;
  --typography-scale-44: 44px;
  --typography-scale-48: 48px;
  --typography-scale-56: 56px;
  --typography-scale-60: 60px;
  --typography-scale-64: 64px;
  --typography-scale-72: 72px;
  --typography-scale-80: 80px;
  --typography-scale-96: 96px;
  --background-avatar-placeholder: var(--colors-system-neutral-50);
  --background-button-disabled: var(--colors-system-neutral-25);
  --background-button-disabledwhite: var(--colors-alpha-white-200);
  --background-button-default-default: var(--colors-brand-primary-700);
  --background-button-default-hover: var(--colors-brand-primary-hover-700);
  --background-button-default-active: var(--colors-brand-primary-500);
  --background-button-defaultwhite-default: var(--colors-alpha-white-1000);
  --background-button-defaultwhite-hover: var(--colors-alpha-white-950);
  --background-button-defaultwhite-active: var(--colors-alpha-white-900);
  --background-button-secondary-default: var(--colors-system-neutral-50);
  --background-button-secondary-hover: var(--colors-system-neutral-hover-50);
  --background-button-secondary-active: var(--colors-system-neutral-200);
  --background-button-tertiary-default: var(--colors-alpha-white-900);
  --background-button-tertiary-hover: var(--colors-system-neutral-hover-50);
  --background-button-tertiary-active: var(--colors-system-neutral-200);
  --background-button-tertiarywhite-default: var(--colors-alpha-primary-900);
  --background-button-tertiarywhite-hover: var(--colors-alpha-white-200);
  --background-button-tertiarywhite-active: var(--colors-alpha-white-300);
  --background-button-ghost-default: var(--colors-alpha-white-900);
  --background-button-ghost-hover: var(--colors-system-neutral-hover-50);
  --background-button-ghost-active: var(--colors-system-neutral-200);
  --background-button-ghostwhite-default: var(--colors-alpha-primary-900);
  --background-button-ghostwhite-hover: var(--colors-alpha-white-200);
  --background-button-ghostwhite-active: var(--colors-alpha-white-300);
  --background-button-blurred-default: var(--colors-alpha-neutral-600);
  --background-button-blurred-hover: var(--colors-alpha-neutral-700);
  --background-button-blurred-active: var(--colors-alpha-neutral-800);
  --background-input-default: var(--colors-alpha-white-1000);
  --background-input-hover: #fff;
  --background-input-active: #fff;
  --background-input-disabled: var(--colors-system-neutral-25);
  --background-tooltip-background: var(--colors-alpha-neutral-1000);
  --background-image-hint-background: var(--colors-alpha-white-100);
  --background-tags-default-subtle: var(--colors-brand-primary-500);
  --background-tags-neutral-default: var(--colors-system-neutral-900);
  --background-brand: var(--colors-brand-primary-700);
  --background-supertitle-default: var(--colors-brand-primary-700);
  --background-navigation-default: var(--colors-brand-primary-700);
  --background-tile-layer-1: var(--colors-system-neutral-50);
  --background-tile-layer-1-hover: var(--colors-system-neutral-hover-50);
  --background-tile-layer-1-active: var(--colors-system-neutral-200);
  --background-selected: var(--colors-alpha-primary-300);
  --background-neutral: var(--colors-system-neutral-900);
  --background-transparent-40: var(--colors-alpha-neutral-400);
  --background-transparent-60: var(--colors-alpha-neutral-600);
  --background-blanket-default: var(--colors-alpha-neutral-600);
  --background-transparent-80: var(--colors-alpha-neutral-800);
  --background-danger: var(--colors-system-red-500);
  --background-danger-subtle: var(--colors-system-red-100);
  --background-warning: var(--colors-system-yellow-500);
  --background-success: var(--colors-system-green-500);
  --background-dropdown-default: var(--colors-alpha-white-900);
  --background-dropdown-hover: var(--colors-system-neutral-hover-50);
  --background-dropdown-active: var(--colors-system-neutral-200);
  --background-breakingnews-default: var(--colors-system-yellow-500);
  --background-gallery-gradient-0: var(--colors-alpha-neutral-0);
  --background-gallery-gradient-95: var(--colors-alpha-neutral-950);
  --background-dynamicnavigation-default: var(--colors-brand-primary-700);
  --text-default: var(--colors-system-neutral-900);
  --text-subtle: var(--colors-system-neutral-700);
  --text-subtlest: var(--colors-system-neutral-600);
  --text-inverse: var(--colors-alpha-white-1000);
  --text-disabled: var(--colors-system-neutral-300);
  --text-selected: var(--colors-brand-primary-700);
  --text-brand: var(--colors-brand-primary-700);
  --text-on-color: var(--colors-alpha-white-1000);
  --text-white: var(--colors-alpha-white-1000);
  --text-white-default: var(--colors-alpha-white-1000);
  --text-white-subtle: var(--colors-alpha-white-900);
  --text-white-subtlest: var(--colors-alpha-white-800);
  --text-white-inverse: var(--colors-brand-primary-800);
  --text-white-disabled: var(--colors-alpha-white-400);
  --text-white-selected: var(--colors-alpha-white-500);
  --text-danger: var(--colors-system-red-700);
  --text-warning: var(--colors-system-yellow-700);
  --text-warning-inverse: var(--colors-system-neutral-900);
  --text-success: var(--colors-system-green-700);
  --link-default-default: var(--colors-brand-primary-700);
  --link-default-hover: var(--colors-brand-primary-hover-700);
  --link-default-visited: var(--colors-system-purple-800);
  --link-disabled: var(--colors-system-neutral-400);
  --link-neutral-default: var(--colors-system-neutral-900);
  --link-neutral-hover: var(--colors-system-neutral-hover-900);
  --link-neutral-visited: var(--colors-system-purple-800);
  --link-neutralwhite-default: var(--colors-alpha-white-1000);
  --link-neutralwhite-hover: var(--colors-alpha-white-900);
  --link-neutralwhite-visited: var(--colors-alpha-white-700);
  --icon-default: var(--colors-system-neutral-900);
  --icon-subtle: var(--colors-system-neutral-700);
  --icon-subtlest: var(--colors-system-neutral-600);
  --icon-inverse: var(--colors-alpha-white-1000);
  --icon-disabled: var(--colors-system-neutral-300);
  --icon-selected: var(--colors-brand-primary-700);
  --icon-brand: var(--colors-brand-primary-700);
  --icon-danger: var(--colors-system-red-800);
  --icon-white: var(--colors-alpha-white-1000);
  --icon-white-default: var(--colors-alpha-white-1000);
  --icon-white-subtle: var(--colors-alpha-white-900);
  --icon-white-subtlest: var(--colors-alpha-white-800);
  --icon-white-inverse: var(--colors-brand-primary-800);
  --icon-white-disabled: var(--colors-alpha-white-400);
  --icon-warning: var(--colors-system-yellow-800);
  --icon-warning-inverse: var(--colors-system-neutral-900);
  --icon-success: var(--colors-system-green-800);
  --border-default: var(--colors-system-neutral-200);
  --border-subtle: var(--colors-system-neutral-100);
  --border-subtlest: var(--colors-system-neutral-50);
  --border-inverse: var(--colors-system-neutral-50);
  --border-disabled: var(--colors-system-neutral-50);
  --border-white-default: var(--colors-alpha-white-400);
  --border-white-subtle: var(--colors-alpha-white-300);
  --border-white-subtlest: var(--colors-alpha-white-200);
  --border-white-disabled: var(--colors-alpha-white-300);
  --border-selected: var(--colors-brand-primary-700);
  --border-focused: var(--colors-brand-primary-500);
  --border-brand: var(--colors-brand-primary-700);
  --border-danger: var(--colors-system-red-700);
  --border-warning: var(--colors-system-yellow-700);
  --border-success: var(--colors-system-green-700);
  --border-avatar-default: var(--colors-alpha-neutral-50);
  --border-transparent: var(--colors-alpha-neutral-50);
  --layers-layer-02: var(--colors-system-neutral-25);
  --surface-default: var(--colors-alpha-white-1000);
  --surface-overlay: var(--colors-alpha-white-1000);
  --focus-color: var(--colors-brand-primary-700);
  --focus-inset: var(--colors-alpha-white-1000);
  --focus-white-color: var(--colors-alpha-white-900);
  --focus-white-inset: var(--colors-brand-primary-700);
  --miscellaneous-quotation: var(--colors-brand-primary-50);
  --gradient-default-0: var(--colors-alpha-primary-0);
  --gradient-default-70: var(--colors-alpha-primary-1000);
  --gradient-neutral-0: var(--colors-alpha-neutral-0);
  --gradient-neutral-20: var(--colors-alpha-neutral-200);
  --gradient-neutral-40: var(--colors-alpha-neutral-400);
  --gradient-neutral-80: var(--colors-alpha-neutral-800);
  --gradient-neutral-95: var(--colors-alpha-neutral-950);
}

@media (max-width: 767px) {
  :root {
    --font-family-default: var(--typography-family-default);
    --font-family-alternative: var(--typography-family-alternative);
    --font-weight-regular: var(--typography-weight-regular);
    --font-weight-medium: var(--typography-weight-medium);
    --font-weight-semibold: var(--typography-weight-semibold);
    --font-weight-bold: var(--typography-weight-bold);
    --font-weight-extrabold: var(--typography-weight-bold);
    --font-weight-black: var(--typography-weight-black);
    --font-size-11: var(--typography-scale-12);
    --font-size-12: var(--typography-scale-12);
    --font-size-13: var(--typography-scale-12);
    --font-size-14: var(--typography-scale-14);
    --font-size-16: var(--typography-scale-16);
    --font-size-18: var(--typography-scale-18);
    --font-size-20: var(--typography-scale-18);
    --font-size-24: var(--typography-scale-20);
    --font-size-28: var(--typography-scale-24);
    --font-size-32: var(--typography-scale-26);
    --font-size-36: var(--typography-scale-30);
    --font-size-48: var(--typography-scale-32);
    --font-size-60: var(--typography-scale-40);
    --font-size-72: var(--typography-scale-44);
    --font-size-96: var(--typography-scale-44);
    --leading-14: var(--typography-scale-14);
    --leading-16: var(--typography-scale-16);
    --leading-20: var(--typography-scale-20);
    --leading-24: var(--typography-scale-24);
    --leading-28: var(--typography-scale-28);
    --leading-32: var(--typography-scale-32);
    --leading-36: var(--typography-scale-36);
    --leading-40: var(--typography-scale-40);
    --leading-44: var(--typography-scale-44);
    --leading-48: var(--typography-scale-48);
    --leading-60: var(--typography-scale-60);
    --leading-64: var(--typography-scale-80);
  }
}

@media (min-width: 768px) and (max-width: 1079px) {
  :root {
    --font-family-default: var(--typography-family-default);
    --font-family-alternative: var(--typography-family-alternative);
    --font-weight-regular: var(--typography-weight-regular);
    --font-weight-medium: var(--typography-weight-medium);
    --font-weight-semibold: var(--typography-weight-semibold);
    --font-weight-bold: var(--typography-weight-bold);
    --font-weight-extrabold: var(--typography-weight-extrabold);
    --font-weight-black: var(--typography-weight-black);
    --font-size-11: var(--typography-scale-11);
    --font-size-12: var(--typography-scale-12);
    --font-size-13: var(--typography-scale-13);
    --font-size-14: var(--typography-scale-14);
    --font-size-16: var(--typography-scale-16);
    --font-size-18: var(--typography-scale-18);
    --font-size-20: var(--typography-scale-20);
    --font-size-24: var(--typography-scale-24);
    --font-size-28: var(--typography-scale-28);
    --font-size-32: var(--typography-scale-32);
    --font-size-36: var(--typography-scale-36);
    --font-size-48: var(--typography-scale-48);
    --font-size-60: var(--typography-scale-60);
    --font-size-72: var(--typography-scale-72);
    --font-size-96: var(--typography-scale-96);
    --leading-14: var(--typography-scale-14);
    --leading-16: var(--typography-scale-16);
    --leading-20: var(--typography-scale-20);
    --leading-24: var(--typography-scale-24);
    --leading-28: var(--typography-scale-28);
    --leading-32: var(--typography-scale-32);
    --leading-36: var(--typography-scale-36);
    --leading-40: var(--typography-scale-40);
    --leading-44: var(--typography-scale-44);
    --leading-48: var(--typography-scale-48);
    --leading-60: var(--typography-scale-60);
    --leading-64: var(--typography-scale-64);
  }
}

@media (min-width: 1080px) {
  :root {
    --font-family-default: var(--typography-family-default);
    --font-family-alternative: var(--typography-family-alternative);
    --font-weight-regular: var(--typography-weight-regular);
    --font-weight-medium: var(--typography-weight-medium);
    --font-weight-semibold: var(--typography-weight-semibold);
    --font-weight-bold: var(--typography-weight-bold);
    --font-weight-extrabold: var(--typography-weight-extrabold);
    --font-weight-black: var(--typography-weight-black);
    --font-size-11: var(--typography-scale-11);
    --font-size-12: var(--typography-scale-12);
    --font-size-13: var(--typography-scale-13);
    --font-size-14: var(--typography-scale-14);
    --font-size-16: var(--typography-scale-16);
    --font-size-18: var(--typography-scale-18);
    --font-size-20: var(--typography-scale-20);
    --font-size-24: var(--typography-scale-24);
    --font-size-28: var(--typography-scale-28);
    --font-size-32: var(--typography-scale-32);
    --font-size-36: var(--typography-scale-36);
    --font-size-48: var(--typography-scale-48);
    --font-size-60: var(--typography-scale-60);
    --font-size-72: var(--typography-scale-72);
    --font-size-96: var(--typography-scale-96);
    --leading-14: var(--typography-scale-14);
    --leading-16: var(--typography-scale-16);
    --leading-20: var(--typography-scale-20);
    --leading-24: var(--typography-scale-24);
    --leading-28: var(--typography-scale-28);
    --leading-32: var(--typography-scale-32);
    --leading-36: var(--typography-scale-36);
    --leading-40: var(--typography-scale-40);
    --leading-44: var(--typography-scale-44);
    --leading-48: var(--typography-scale-48);
    --leading-60: var(--typography-scale-60);
    --leading-64: var(--typography-scale-64);
  }
}

:root {
  --shadow-xs-1: rgba(24, 24, 27, .05);
  --shadow-xs: 0 1px 2px 0 var(--shadow-xs-1);
  --shadow-sm-1: rgba(24, 24, 27, .1);
  --shadow-sm-2: rgba(24, 24, 27, .1);
  --shadow-sm: 0 1px 2px -1px var(--shadow-sm-1), 0 1px 3px 0 var(--shadow-sm-2);
  --shadow-md-1: rgba(24, 24, 27, .1);
  --shadow-md-2: rgba(24, 24, 27, .1);
  --shadow-md: 0 2px 4px -2px var(--shadow-md-1), 0 4px 6px -1px var(--shadow-md-2);
  --shadow-lg-1: rgba(24, 24, 27, .1);
  --shadow-lg-2: rgba(24, 24, 27, .1);
  --shadow-lg: 0 4px 6px -4px var(--shadow-lg-1), 0 10px 15px -3px var(--shadow-lg-2);
  --shadow-xl-1: rgba(24, 24, 27, .1);
  --shadow-xl-2: rgba(24, 24, 27, .1);
  --shadow-xl: 0 8px 10px -6px var(--shadow-xl-1), 0 20px 25px -5px var(--shadow-xl-2);
  --shadow-2xl-1: rgba(24, 24, 27, .25);
  --shadow-2xl: 0 25px 50px -12px var(--shadow-2xl-1);
  --shadow-text-1: rgba(24, 24, 27, .2);
  --shadow-text-2: rgba(24, 24, 27, .4);
  --shadow-text: 0 0 4px var(--shadow-text-1), 0 1px 2px var(--shadow-text-2);
}

.typography {
  font-family: var(--font-family-default);
  font-size: var(--font-size-13);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--leading-20);
}

.typography--underline {
  text-decoration: underline;
}

.typography--uppercase {
  text-transform: uppercase;
}

.typography--lowercase {
  text-transform: lowercase;
}

.typography--capitalize {
  text-transform: capitalize;
}

.typography--italic {
  font-style: italic;
}

.typography--48-black {
  font-size: var(--font-size-48);
  line-height: var(--leading-60);
  letter-spacing: -.48px;
  font-weight: var(--font-weight-black);
}

@media screen and (min-width: 1080px) {
  .typography--48-black {
    letter-spacing: -.72px;
  }
}

.typography--36-black {
  font-size: var(--font-size-36);
  line-height: var(--leading-40);
  letter-spacing: -.45px;
  font-weight: var(--font-weight-black);
}

@media screen and (min-width: 1080px) {
  .typography--36-black {
    letter-spacing: -.54px;
  }
}

.typography--32-black {
  font-size: var(--font-size-32);
  line-height: var(--leading-36);
  letter-spacing: -.39px;
  font-weight: var(--font-weight-black);
}

@media screen and (min-width: 1080px) {
  .typography--32-black {
    letter-spacing: -.48px;
  }
}

.typography--28-black {
  font-size: var(--font-size-28);
  line-height: var(--leading-36);
  letter-spacing: -.36px;
  font-weight: var(--font-weight-black);
}

@media screen and (min-width: 1080px) {
  .typography--28-black {
    letter-spacing: -.42px;
  }
}

.typography--24-black {
  font-size: var(--font-size-24);
  line-height: var(--leading-32);
  letter-spacing: .2px;
  font-weight: var(--font-weight-black);
}

@media screen and (min-width: 1080px) {
  .typography--24-black {
    letter-spacing: .24px;
  }
}

.typography--20, .typography--20-md, .typography--20-sb, .typography--20-bold, .typography--20-black {
  font-size: var(--font-size-20);
  line-height: var(--leading-28);
  letter-spacing: .18px;
}

@media screen and (min-width: 1080px) {
  .typography--20, .typography--20-md, .typography--20-sb, .typography--20-bold, .typography--20-black {
    letter-spacing: .2px;
  }
}

.typography--18, .typography--18-md, .typography--18-sb, .typography--18-bold, .typography--18-black {
  font-size: var(--font-size-18);
  line-height: var(--leading-28);
  letter-spacing: .18px;
}

.typography--16, .typography--16-md, .typography--16-sb, .typography--16-bold, .typography--16-black {
  font-size: var(--font-size-16);
  line-height: var(--leading-24);
  letter-spacing: .16px;
}

.typography--14, .typography--14-md, .typography--14-sb, .typography--14-bold, .typography--14-black {
  font-size: var(--font-size-14);
  line-height: var(--leading-20);
  letter-spacing: .14px;
}

.typography--13 {
  font-size: var(--font-size-13);
  line-height: var(--leading-20);
}

.typography--12, .typography--12-md, .typography--12-sb, .typography--12-bold, .typography--12-black {
  font-size: var(--font-size-12);
  line-height: var(--leading-16);
  letter-spacing: .12px;
}

.typography--11, .typography--11-md {
  font-size: var(--font-size-11);
  letter-spacing: .24px;
  line-height: var(--leading-14);
}

@media screen and (min-width: 1080px) {
  .typography--11, .typography--11-md {
    letter-spacing: .22px;
  }
}

.typography--20-md, .typography--18-md, .typography--16-md, .typography--14-md, .typography--13-md, .typography--12-md, .typography--11-md {
  font-weight: var(--font-weight-medium);
}

.typography--20-sb, .typography--18-sb, .typography--16-sb, .typography--14-sb, .typography--13-sb, .typography--12-sb, .typography--11-sb {
  font-weight: var(--font-weight-semibold);
}

.typography--20-bold, .typography--18-bold, .typography--16-bold, .typography--14-bold, .typography--13-bold, .typography--12-bold, .typography--11-bold {
  font-weight: var(--font-weight-bold);
}

.typography--20-black, .typography--18-black, .typography--16-black, .typography--14-black, .typography--13-black, .typography--12-black, .typography--11-black {
  font-weight: var(--font-weight-black);
}

.typography--body1, .typography--body1-bold {
  font-size: var(--font-size-20);
  letter-spacing: .18px;
}

@media screen and (min-width: 1080px) {
  .typography--body1, .typography--body1-bold {
    letter-spacing: .2px;
  }
}

.typography--body2, .typography--body2-bold {
  font-size: var(--font-size-18);
  letter-spacing: .18px;
}

.typography--body3, .typography--body3-bold {
  font-size: var(--font-size-16);
  letter-spacing: .16px;
}

.typography--body1, .typography--body2, .typography--body3 {
  font-weight: var(--font-weight-regular);
  line-height: 162.5%;
}

.typography--body1-bold, .typography--body2-bold, .typography--body3-bold {
  font-weight: var(--font-weight-bold);
  line-height: 162.5%;
}

.typography--heading1-black {
  font-size: var(--font-size-48);
  letter-spacing: -.48px;
}

@media screen and (min-width: 1080px) {
  .typography--heading1-black {
    letter-spacing: -.72px;
  }
}

.typography--heading2-black {
  font-size: var(--font-size-36);
  letter-spacing: -.45px;
}

@media screen and (min-width: 1080px) {
  .typography--heading2-black {
    letter-spacing: -.54px;
  }
}

.typography--heading3-black {
  font-size: var(--font-size-32);
  letter-spacing: -.39px;
}

@media screen and (min-width: 1080px) {
  .typography--heading3-black {
    letter-spacing: -.48px;
  }
}

.typography--heading4-black {
  font-size: var(--font-size-28);
  letter-spacing: -.36px;
}

@media screen and (min-width: 1080px) {
  .typography--heading4-black {
    letter-spacing: -.42px;
  }
}

.typography--heading5-black {
  font-size: var(--font-size-24);
  letter-spacing: .2px;
}

@media screen and (min-width: 1080px) {
  .typography--heading5-black {
    letter-spacing: .24px;
  }
}

.typography--heading6-black {
  font-size: var(--font-size-20);
  letter-spacing: .18px;
}

@media screen and (min-width: 1080px) {
  .typography--heading6-black {
    letter-spacing: .2px;
  }
}

.typography--heading7-black {
  font-size: var(--font-size-18);
  letter-spacing: .18px;
}

.typography--heading8-black {
  font-size: var(--font-size-16);
  letter-spacing: .16px;
}

.typography--heading9-black {
  font-size: var(--font-size-14);
  letter-spacing: .14px;
}

.typography--heading10-black {
  font-size: var(--font-size-12);
  letter-spacing: .12px;
}

.typography--heading1-black, .typography--heading2-black, .typography--heading3-black, .typography--heading4-black, .typography--heading5-black, .typography--heading6-black, .typography--heading7-black, .typography--heading8-black, .typography--heading9-black, .typography--heading10-black {
  font-family: var(--font-family-default);
  line-height: 125%;
  font-weight: var(--font-weight-black);
}

.typography--supertitle {
  font-family: var(--font-family-alternative);
  font-size: var(--font-size-24);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  letter-spacing: .3px;
  line-height: 125%;
}

@media screen and (min-width: 1080px) {
  .typography--supertitle {
    letter-spacing: .36px;
  }
}

.typography--title {
  font-family: var(--font-family-alternative);
  font-size: var(--font-size-60);
  font-style: normal;
  font-weight: var(--font-weight-extrabold);
  letter-spacing: .6px;
  line-height: 100%;
}

@media screen and (min-width: 1080px) {
  .typography--title {
    letter-spacing: .9px;
  }
}

.icon {
  width: var(--icon-size-24);
  height: var(--icon-size-24);
}

.avatar {
  border-radius: var(--radius-full);
  border: .5px solid var(--border-avatar-default);
  background: var(--background-avatar-placeholder);
  width: var(--icon-size-24);
  height: var(--icon-size-24);
  box-sizing: border-box;
  cursor: default;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: flex;
}

.avatar img {
  border-radius: var(--radius-full);
  max-width: none;
}

.avatar--24 {
  width: 24px;
  height: 24px;
}

.avatar--32 {
  width: 32px;
  height: 32px;
}

.avatar--36 {
  width: 36px;
  height: 36px;
}

.avatar--40 {
  border: .75px solid var(--border-avatar-default);
  width: 40px;
  height: 40px;
}

.avatar--image {
  background: none;
  border: 0;
  padding: 0;
}

.avatar--image.avatar--24 {
  padding: 4px;
}

.avatar--image.avatar--32 {
  padding: 6px;
}

.avatar--image.avatar--36 {
  padding: 7px;
}

.avatar--image.avatar--40 {
  padding: 8px;
}

.avatar--initials {
  color: var(--icon-default);
}

.avatar--initials.avatar--24 {
  padding: 4px 0;
}

.avatar--initials.avatar--32 {
  padding: 6px 0;
}

.avatar--initials.avatar--36 {
  padding: 7px 0;
}

.avatar--initials.avatar--40 {
  padding: 8px 0;
}

.link-2024 {
  align-items: center;
  gap: var(--spacing-6);
  flex-direction: row;
  text-decoration: none;
  display: inline-flex;
}

.link-2024 svg {
  width: 16px;
  height: 16px;
}

.link-2024 .typography {
  text-underline-offset: 4px;
  text-decoration: none;
}

.link-2024--neutral-white {
  color: var(--link-neutralwhite-default);
  text-decoration: none;
}

.link-2024--neutral-white svg path {
  fill: var(--link-neutralwhite-default);
}

.link-2024--neutral-white:hover {
  color: var(--link-neutralwhite-hover);
  text-decoration: underline;
}

.link-2024--neutral-white:hover svg path {
  fill: var(--link-neutralwhite-hover);
}

.link-2024--neutral-white:active {
  color: var(--text-white-default);
  border: 1px solid var(--focus-white-color);
  text-decoration: underline;
}

.link-2024--neutral-white:active svg path {
  fill: var(--icon-white-default);
}

.link-2024--neutral-white:focus {
  color: var(--link-neutralwhite-default);
  border: 1px solid var(--focus-white-color);
  text-decoration: underline;
}

.link-2024--neutral-white:focus svg path {
  fill: var(--link-neutralwhite-default);
}

.link-2024--neutral-white:visited {
  color: var(--link-neutralwhite-visited);
  text-decoration: underline;
}

.link-2024--neutral-white:visited svg path {
  fill: var(--link-neutralwhite-visited);
}

.link-2024--neutral-white.link--disabled {
  color: var(--text-white-disabled);
  pointer-events: none;
  text-decoration: none;
}

.link-2024--neutral-white.link--disabled svg path {
  fill: var(--icon-white-disabled);
}

.link-2024--neutral {
  color: var(--link-neutral-default);
  text-decoration: none;
}

.link-2024--neutral svg path {
  fill: var(--link-neutral-default);
}

.link-2024--neutral:hover {
  color: var(--link-neutral-hover);
  text-decoration: underline;
}

.link-2024--neutral:hover svg path {
  fill: var(--link-neutral-hover);
}

.link-2024--neutral:active {
  color: var(--text-default);
  border: 1px solid var(--focus-color);
  text-decoration: underline;
}

.link-2024--neutral:active svg path {
  fill: var(--icon-default);
}

.link-2024--neutral:focus {
  color: var(--link-neutral-default);
  border: 1px solid var(--focus-color);
  text-decoration: underline;
}

.link-2024--neutral:focus svg path {
  fill: var(--link-neutral-default);
}

.link-2024--neutral:visited {
  color: var(--link-neutral-visited);
  text-decoration: underline;
}

.link-2024--neutral:visited svg path {
  fill: var(--link-neutral-visited);
}

.link-2024--neutral.link--disabled {
  color: var(--text-disabled);
  pointer-events: none;
  text-decoration: none;
}

.link-2024--neutral.link--disabled svg path {
  fill: var(--icon-disabled);
}

.link-2024--default {
  gap: var(--spacing-8);
  color: var(--link-default-default);
  text-decoration: none;
}

.link-2024--default svg {
  width: 20px;
  height: 20px;
}

.link-2024--default svg path {
  fill: var(--link-default-default);
}

.link-2024--default:hover {
  color: var(--link-default-hover);
  text-decoration: underline;
}

.link-2024--default:hover svg path {
  fill: var(--link-default-hover);
}

.link-2024--default:active {
  color: var(--text-default);
  border: 1px solid var(--focus-color);
  text-decoration: underline;
}

.link-2024--default:active svg path {
  fill: var(--icon-default);
}

.link-2024--default:focus {
  color: var(--link-default-default);
  border: 1px solid var(--focus-color);
  text-decoration: underline;
}

.link-2024--default:focus svg path {
  fill: var(--link-default-default);
}

.link-2024--default:visited {
  color: var(--link-neutral-visited);
  text-decoration: underline;
}

.link-2024--default:visited svg path {
  fill: var(--link-neutral-visited);
}

.link-2024--default.link--disabled {
  color: var(--text-disabled);
  pointer-events: none;
  text-decoration: none;
}

.link-2024--default.link--disabled svg path {
  fill: var(--icon-disabled);
}

.logo-2024 svg {
  height: var(--icon-size-24);
  width: 69.398px;
  display: block;
}

.logo-2024--box svg {
  width: 79px;
  height: 32px;
}

.logo-2024--neutral svg path {
  fill: var(--icon-default);
}

.logo-2024--inverse svg path {
  fill: var(--icon-inverse);
}

.logo-2024--default svg path {
  fill: var(--icon-brand);
}

.buttons-store {
  gap: 6px;
  display: flex;
}

.buttons-store svg g {
  width: 128px;
  height: 40px;
}

.buttons-store--horizontal {
  flex-direction: row;
}

.buttons-store--vertical {
  flex-direction: column;
}

.label {
  align-items: center;
  gap: var(--spacing-0);
  padding: var(--spacing-4) var(--spacing-12);
  border-radius: var(--radius-6);
  background: var(--background-neutral);
  display: inline-flex;
}

.label .typography {
  color: var(--text-inverse);
}

.super-title {
  padding: var(--spacing-2) var(--spacing-8) var(--spacing-4) var(--spacing-8);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-0);
  border-radius: var(--radius-0);
  background: var(--background-brand);
  display: inline-flex;
}

.super-title .typography {
  color: var(--text-inverse);
}

@media (min-width: 1080px) {
  .super-title {
    padding: 3px var(--spacing-10) var(--spacing-6) var(--spacing-10);
  }
}

.button-2024 {
  cursor: pointer;
  border-radius: var(--radius-6);
  box-sizing: border-box;
  border: 0;
  flex-shrink: 0;
  align-items: center;
  width: -webkit-fit-content;
  width: fit-content;
  display: inline-flex;
}

.button-2024 .typography {
  padding: 0 4px;
}

.button-2024--default {
  background: var(--background-button-default-default);
  color: var(--text-inverse);
}

.button-2024--default .typography {
  color: var(--text-inverse);
}

.button-2024--default svg path {
  fill: var(--icon-inverse);
}

.button-2024--default:hover {
  background: var(--background-button-default-hover);
  text-decoration: none;
}

.button-2024--default:active {
  background: var(--background-button-default-active);
}

.button-2024--default:focus {
  background: var(--background-button-default-default);
  box-shadow: 0 0 0 2px var(--focus-inset), 0 0 0 4px var(--focus-color);
}

.button-2024--default:disabled, .button-2024--default-disabled {
  background: var(--background-button-disabled);
  cursor: not-allowed;
}

.button-2024--default:disabled .typography, .button-2024--default-disabled .typography {
  color: var(--text-disabled);
}

.button-2024--default:disabled svg path, .button-2024--default-disabled svg path {
  fill: var(--icon-disabled);
}

.button-2024--default-white {
  background: var(--background-button-defaultwhite-default);
  color: var(--text-white-inverse);
}

.button-2024--default-white .typography {
  color: var(--text-white-inverse);
}

.button-2024--default-white svg path {
  fill: var(--icon-white-inverse);
}

.button-2024--default-white:hover {
  background: var(--background-button-defaultwhite-hover);
  text-decoration: none;
}

.button-2024--default-white:active {
  background: var(--background-button-defaultwhite-active);
}

.button-2024--default-white:focus {
  background: var(--background-button-defaultwhite-default);
  box-shadow: 0 0 0 2px var(--focus-white-inset), 0 0 0 4px var(--focus-white-color);
}

.button-2024--default-white:disabled, .button-2024--default-white-disabled {
  background: var(--background-button-disabledwhite);
  cursor: not-allowed;
}

.button-2024--default-white:disabled .typography, .button-2024--default-white-disabled .typography {
  color: var(--text-white-disabled);
}

.button-2024--default-white:disabled svg path, .button-2024--default-white-disabled svg path {
  fill: var(--icon-white-disabled);
}

.button-2024--secondary {
  background: var(--background-button-secondary-default);
  color: var(--text-default);
}

.button-2024--secondary .typography {
  color: var(--text-default);
}

.button-2024--secondary svg path {
  fill: var(--icon-default);
}

.button-2024--secondary:hover {
  background: var(--background-button-secondary-hover);
  text-decoration: none;
}

.button-2024--secondary:active {
  background: var(--background-button-secondary-active);
}

.button-2024--secondary:focus {
  background: var(--background-button-secondary-default);
  box-shadow: 0 0 0 2px var(--focus-inset), 0 0 0 4px var(--focus-color);
}

.button-2024--secondary:disabled, .button-2024--secondary-disabled {
  background: var(--background-button-disabled);
  cursor: not-allowed;
}

.button-2024--secondary:disabled .typography, .button-2024--secondary-disabled .typography {
  color: var(--text-disabled);
}

.button-2024--secondary:disabled svg path, .button-2024--secondary-disabled svg path {
  fill: var(--icon-disabled);
}

.button-2024--tertiary {
  background: var(--background-button-tertiary-default);
  color: var(--text-default);
  border: 1px solid var(--border-subtle);
}

.button-2024--tertiary .typography {
  color: var(--text-default);
}

.button-2024--tertiary svg path {
  fill: var(--icon-default);
}

.button-2024--tertiary:hover {
  background: var(--background-button-tertiary-hover);
  text-decoration: none;
}

.button-2024--tertiary:active {
  background: var(--background-button-tertiary-active);
}

.button-2024--tertiary:focus {
  background: var(--background-button-tertiary-default);
  box-shadow: 0 0 0 2px var(--focus-inset), 0 0 0 4px var(--focus-color);
}

.button-2024--tertiary:disabled, .button-2024--tertiary-disabled {
  background: var(--background-button-tertiary-default);
  cursor: not-allowed;
  border: 1px solid var(--border-disabled);
}

.button-2024--tertiary:disabled .typography, .button-2024--tertiary-disabled .typography {
  color: var(--text-disabled);
}

.button-2024--tertiary:disabled svg path, .button-2024--tertiary-disabled svg path {
  fill: var(--icon-disabled);
}

.button-2024--tertiary-white {
  background: var(--background-button-tertiarywhite-default);
  border: 1px solid var(--border-white-subtle);
  color: var(--text-white-default);
}

.button-2024--tertiary-white .typography {
  color: var(--text-white-default);
}

.button-2024--tertiary-white svg path {
  fill: var(--icon-white-default);
}

.button-2024--tertiary-white:hover {
  background: var(--background-button-tertiarywhite-hover);
  text-decoration: none;
}

.button-2024--tertiary-white:active {
  background: var(--background-button-tertiarywhite-active);
}

.button-2024--tertiary-white:focus {
  background: var(--background-button-tertiarywhite-default);
  box-shadow: 0 0 0 2px var(--focus-white-inset), 0 0 0 4px var(--focus-white-color);
}

.button-2024--tertiary-white:disabled, .button-2024--tertiary-white-disabled {
  background: var(--background-button-tertiarywhite-default);
  border: 1px solid var(--border-white-disabled);
  cursor: not-allowed;
}

.button-2024--tertiary-white:disabled .typography, .button-2024--tertiary-white-disabled .typography {
  color: var(--text-white-disabled);
}

.button-2024--tertiary-white:disabled svg path, .button-2024--tertiary-white-disabled svg path {
  fill: var(--icon-white-disabled);
}

.button-2024--ghost {
  color: var(--text-default);
  background: none;
  border: none;
}

.button-2024--ghost .typography {
  color: var(--text-default);
}

.button-2024--ghost svg path {
  fill: var(--icon-default);
}

.button-2024--ghost:hover {
  background: var(--background-button-ghost-hover);
  text-decoration: none;
}

.button-2024--ghost:active {
  background: var(--background-button-ghost-active);
}

.button-2024--ghost:focus {
  background: var(--background-button-ghost-default);
  box-shadow: 0 0 0 2px var(--focus-inset), 0 0 0 4px var(--focus-color);
}

.button-2024--ghost:disabled, .button-2024--ghost-disabled {
  cursor: not-allowed;
  background: none;
  border: none;
}

.button-2024--ghost:disabled .typography, .button-2024--ghost-disabled .typography {
  color: var(--text-disabled);
}

.button-2024--ghost:disabled svg path, .button-2024--ghost-disabled svg path {
  fill: var(--icon-disabled);
}

.button-2024--ghost-white {
  color: var(--text-white-default);
  background: none;
  border: none;
}

.button-2024--ghost-white .typography {
  color: var(--text-white-default);
}

.button-2024--ghost-white svg path {
  fill: var(--icon-white-default);
}

.button-2024--ghost-white:hover {
  background: var(--background-button-ghostwhite-hover);
  text-decoration: none;
}

.button-2024--ghost-white:active {
  background: var(--background-button-ghostwhite-active);
}

.button-2024--ghost-white:focus {
  background: var(--background-button-ghostwhite-default);
  box-shadow: 0 0 0 2px var(--focus-inset), 0 0 0 4px var(--focus-color);
}

.button-2024--ghost-white:disabled, .button-2024--ghost-white-disabled {
  cursor: not-allowed;
  background: none;
  border: none;
}

.button-2024--ghost-white:disabled .typography, .button-2024--ghost-white-disabled .typography {
  color: var(--text-white-disabled);
}

.button-2024--ghost-white:disabled svg path, .button-2024--ghost-white-disabled svg path {
  fill: var(--icon-white-disabled);
}

.button-2024--blurred {
  background: var(--background-button-blurred-default);
  color: var(--text-inverse);
}

.button-2024--blurred .typography {
  color: var(--text-inverse);
}

.button-2024--blurred svg path {
  fill: var(--icon-inverse);
}

.button-2024--blurred:hover {
  background: var(--background-button-blurred-hover);
  text-decoration: none;
}

.button-2024--blurred:active {
  background: var(--background-button-blurred-active);
}

.button-2024--blurred:focus {
  background: var(--background-button-blurred-default);
  box-shadow: 0 0 0 2px var(--focus-inset), 0 0 0 4px var(--focus-color);
}

.button-2024--blurred:disabled, .button-2024--blurred-disabled {
  background: var(--background-button-disabled);
  cursor: not-allowed;
}

.button-2024--blurred:disabled .typography, .button-2024--blurred-disabled .typography {
  color: var(--text-disabled);
}

.button-2024--blurred:disabled svg path, .button-2024--blurred-disabled svg path {
  fill: var(--icon-disabled);
}

.button-2024--28 {
  padding: var(--spacing-2) var(--spacing-8);
  gap: var(--spacing-0);
  height: 28px;
}

.button-2024--32 {
  padding: var(--spacing-6) var(--spacing-8);
  gap: var(--spacing-4);
  height: 32px;
}

.button-2024--36 {
  padding: var(--spacing-6) var(--spacing-12);
  gap: var(--spacing-6);
  height: 36px;
}

.button-2024--40 {
  padding: var(--spacing-8) var(--spacing-16);
  gap: var(--spacing-6);
  height: 40px;
}

.button-2024--28.button-2024--icon {
  max-width: 28px;
}

.button-2024--32.button-2024--icon {
  max-width: 32px;
}

.button-2024--36.button-2024--icon {
  max-width: 36px;
}

.button-2024--40.button-2024--icon {
  max-width: 40px;
}

.button-2024--28 img, .button-2024--28 svg, .button-2024--32 img, .button-2024--32 svg {
  width: 20px;
  height: 20px;
}

.button-2024--36 img, .button-2024--36 svg, .button-2024--40 img, .button-2024--40 svg {
  width: 24px;
  height: 24px;
}

.button-2024--28.button-2024--svg-only {
  padding: 5px;
}

.button-2024--32.button-2024--svg-only {
  padding: 7px;
}

.button-2024--36.button-2024--svg-only {
  padding: var(--spacing-8);
}

.button-2024--40.button-2024--svg-only {
  padding: var(--spacing-10);
}

.dropdown {
  width: -webkit-fit-content;
  width: fit-content;
  display: inline-block;
  position: relative;
}

.dropdown:hover .dropdown__content {
  opacity: 1;
  visibility: visible;
}

@starting-style {
  .dropdown:hover .dropdown__content {
    visibility: inherit;
    opacity: 0;
  }
}

.dropdown--left .dropdown__content {
  right: 0;
}

.dropdown--right .dropdown__content {
  left: 0;
}

.dropdown .dropdown__divider {
  background: var(--border-subtlest);
  margin: var(--spacing-8) 0;
  border: 0;
  width: 100%;
  height: 1px;
}

.dropdown__content {
  z-index: 1;
  border-radius: var(--radius-6);
  border: 1px solid var(--border-subtlest);
  background: var(--surface-overlay);
  box-shadow: var(--shadow-md);
  padding: var(--spacing-8) var(--spacing-0);
  opacity: 0;
  visibility: hidden;
  transition-behavior: allow-discrete;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 280px;
  transition: visibility .3s linear, opacity .3s linear;
  position: absolute;
}

.dropdown__item {
  padding: var(--spacing-10) var(--spacing-16);
  box-sizing: border-box;
  align-items: center;
  gap: var(--spacing-8);
  color: var(--text-default);
  border: 0;
  flex-direction: row;
  width: 100%;
  max-width: 280px;
  text-decoration: none;
  display: flex;
}

.dropdown__item svg {
  width: 18px;
  height: 18px;
}

.dropdown__item svg path {
  fill: var(--icon-default);
}

.dropdown__item:hover {
  background: var(--background-dropdown-hover);
  text-decoration: none;
}

.dropdown__item:hover .typography {
  text-decoration: none;
}

.dropdown__item:active {
  background: var(--background-dropdown-active);
}

.dropdown__item:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.dropdown__item:disabled .typography {
  color: var(--text-disabled);
}

.dropdown__item:disabled svg path {
  fill: var(--icon-disabled);
}

.dropdown__item:visited .typography {
  color: var(--text-default);
}

.loader {
  border-radius: 50%;
  animation: 2s linear infinite spin;
}

.loader--neutral-white {
  border: 16px solid var(--icon-white-disabled);
  border-top: 16px solid var(--icon-white-default);
  border-width: 3px;
}

.loader--default {
  border: 16px solid var(--icon-disabled);
  border-top: 16px solid var(--icon-default);
  border-width: 3px;
}

.loader--18 {
  width: 18px;
  height: 18px;
}

.loader--20 {
  width: 20px;
  height: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tooltip {
  pointer-events: none;
  z-index: 1000;
  cursor: help;
  border-radius: var(--radius-8);
  background: var(--background-tooltip-background);
  padding: var(--spacing-8) var(--spacing-12);
  align-items: flex-start;
  gap: var(--spacing-2);
  flex-direction: column;
  width: -webkit-max-content;
  width: max-content;
  transition: opacity .2s;
  display: none;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 1px 2px rgba(22, 33, 45, .06);
}

.tooltip--visible {
  display: flex;
}

.tooltip .tooltip__text {
  color: var(--text-white-default);
  font-family: var(--font-family-default);
  font-size: var(--font-size-12);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--leading-16);
  letter-spacing: .12px;
}

.tooltip .tooltip__supporting-text {
  color: var(--text-white-subtlest);
  font-family: var(--font-family-default, Inter);
  font-size: var(--font-size-12);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: var(--leading-16);
  letter-spacing: .12px;
}

.tooltip--top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip--bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip--left {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

.tooltip--right {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.tooltip__parent {
  cursor: help;
  position: relative;
}

.hint {
  position: relative;
}

.hint .hint__text {
  vertical-align: middle;
  background: var(--surface-overlay);
  padding: var(--spacing-8) var(--spacing-16);
  box-shadow: var(--shadow-md);
  border-radius: var(--radius-6);
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
}

.hint:hover .hint__text {
  display: inline-block;
}

.form-validation-message {
  color: var(--text-danger);
}

.form-textarea {
  gap: var(--spacing-6);
  flex-direction: column;
  width: 100%;
  display: flex;
}

.form-textarea .form-textarea__input {
  border-radius: var(--radius-6);
  border: 1px solid var(--border-default);
  background: var(--background-input-default);
  font-family: var(--font-family-default);
  font-size: var(--font-size-16);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--leading-24);
  letter-spacing: .16px;
  color: var(--text-default);
  padding: var(--spacing-12) var(--spacing-16);
  height: 160px;
  box-shadow: 0 1px 2px rgba(22, 33, 45, .05);
}

.form-textarea .form-textarea__input::placeholder {
  color: var(--text-subtlest);
}

.form-textarea .form-textarea__input:focus {
  border: 1px solid var(--border-default);
  background: var(--background-input-default);
  box-shadow: 0 0 0 2px var(--focus-inset), 0 0 0 4px var(--background-neutral), 0 1px 2px 0 rgba(22, 33, 45, .05);
}

.form-textarea .form-textarea__input:disabled {
  color: var(--text-disabled);
  border: 1px solid var(--border-disabled);
  background: var(--background-input-disabled);
}

.form-textarea .form-textarea__label .asterisk {
  color: var(--text-brand);
}

.form-textarea .form-textarea__counter {
  color: var(--text-subtlest);
}

.error .form-textarea__input {
  border: 1px solid var(--border-danger);
}

.error .form-textarea__input:focus {
  border: 1px solid var(--border-danger);
  box-shadow: 0 0 0 2px var(--focus-inset), 0 0 0 4px var(--focus-color), 0 1px 2px 0 rgba(22, 33, 45, .05);
}

.error .form-textarea__counter {
  color: var(--text-danger);
}

.form-input {
  gap: var(--spacing-6);
  flex-direction: column;
  width: 100%;
  display: flex;
}

.form-input .form-input__label {
  color: var(--text-default);
}

.form-input .form-input__label .asterisk {
  color: var(--text-brand);
}

.form-input .form-input__input {
  border-radius: var(--radius-6);
  border: 1px solid var(--border-default);
  background: var(--background-input-default);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-regular);
  line-height: var(--leading-24);
  color: var(--text-default);
  padding: var(--spacing-8) var(--spacing-16);
  max-height: 40px;
  box-shadow: 0 1px 2px rgba(22, 33, 45, .05);
}

.form-input .form-input__input::placeholder {
  color: var(--text-subtlest);
}

.form-input .form-input__input:focus {
  border: 1px solid var(--border-default);
  background: var(--background-input-default);
  box-shadow: 0 0 0 2px var(--focus-inset), 0 0 0 4px var(--background-neutral), 0 1px 2px 0 rgba(22, 33, 45, .05);
}

.form-input .form-input__input:disabled {
  color: var(--text-disabled);
  border: 1px solid var(--border-disabled);
  background: var(--background-input-disabled);
}

.form-input--required .form-input__label .asterisk {
  display: inline;
}

.error .form-input__input {
  border: 1px solid var(--border-danger);
}

.error .form-input__input:focus {
  border: 1px solid var(--border-danger);
  box-shadow: 0 0 0 2px var(--focus-inset), 0 0 0 4px var(--focus-color), 0 1px 2px 0 rgba(22, 33, 45, .05);
}

.form-radio-button {
  gap: var(--spacing-6);
  flex-direction: column;
  width: 100%;
  display: flex;
}

.form-radio-button__label {
  color: var(--text-default);
}

.form-radio-button__label .asterisk {
  color: var(--text-brand);
}

.form-radio-button__options {
  gap: var(--spacing-4);
  flex-flow: wrap;
  display: flex;
}

.form-radio-button__option input[type="radio"] {
  display: none;
}

.form-radio-button__option input[type="radio"]:not(:disabled) ~ label {
  cursor: pointer;
}

.form-radio-button__option input[type="radio"]:disabled ~ label {
  color: var(--text-inverse);
  background: var(--background-button-tertiary-hover);
  box-shadow: none;
  cursor: not-allowed;
}

.form-radio-button__option input[type="radio"]:checked + label {
  color: var(--text-inverse);
  background: var(--background-button-default-default);
}

.form-radio-button__option label {
  color: var(--text-default);
  padding: var(--spacing-8) var(--spacing-16);
  align-items: center;
  gap: var(--spacing-6);
  border-radius: var(--radius-6);
  border: 1px solid var(--border-subtle);
  background: var(--background-button-tertiary-default);
  box-sizing: border-box;
  font-family: var(--font-family-default);
  font-size: var(--font-size-14);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--leading-20);
  letter-spacing: .14px;
  height: 40px;
  display: flex;
}

.form-radio-button__option label:hover {
  background: var(--background-button-tertiary-hover);
}

.form-alert {
  border-radius: var(--radius-6);
  padding: var(--spacing-12) var(--spacing-16);
  border: 1px solid var(--border-danger);
  background: var(--background-danger-subtle);
  gap: var(--spacing-16);
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.form-alert__content {
  gap: var(--spacing-6);
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.form-alert__title, .form-alert__description {
  color: var(--text-default);
}

.form-alert__icon svg path {
  fill: var(--icon-danger);
}

.form-alert__placeholder {
  width: 100%;
}

.swiper {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.swiper__wrapper {
  scroll-snap-type: x proximity;
  scrollbar-width: none;
  overflow-x: scroll;
  overflow-y: hidden;
}

.swiper__wrapper::-webkit-scrollbar {
  display: none;
}

.swiper__items {
  padding: var(--spacing-4) 12px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  transition: transform .3s ease-in-out;
  display: flex;
  position: relative;
}

.swiper__items a, .swiper__items .typography {
  text-decoration: none;
}

.swiper__arrow {
  cursor: pointer;
  z-index: 20;
  max-height: 36px;
  display: none;
  position: absolute;
  top: 50%;
  bottom: 0;
  transform: translateY(-50%);
}

.swiper__arrow--left {
  left: 0;
}

.swiper__arrow--right {
  right: 0;
}

.swiper__arrow-gradient {
  z-index: 10;
  width: 72px;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
}

.swiper__arrow-gradient--left {
  background: linear-gradient(90deg, var(--background-tile-layer-1) 50%, rgba(227, 6, 19, 0) 100%);
  left: 0;
}

.swiper__arrow-gradient--right {
  background: linear-gradient(270deg, var(--background-tile-layer-1) 50%, rgba(227, 6, 19, 0) 100%);
  right: 0;
}

[data-swiper] {
  position: relative;
}

.link-list {
  align-items: flex-start;
}

.link-list li {
  align-items: center;
  display: flex;
}

.link-list--vertical {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.link-list--horizontal {
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 8px 24px;
  display: inline-flex;
}

.social-icon-list {
  flex-direction: row;
  gap: 8px;
  display: flex;
}

a:hover .article-card {
  text-decoration: none;
}

.article-card {
  justify-content: start;
  align-items: start;
  gap: var(--spacing-8);
  flex-direction: column;
  width: 100%;
  display: flex;
}

.article-card .article-card__image {
  max-width: 100%;
  display: block;
  overflow: hidden;
}

.article-card .article-card__image img {
  width: 100%;
  height: auto;
  transition: transform .3s ease-in-out;
}

.article-card .article-card__text {
  color: var(--text-subtle);
  gap: var(--spacing-6);
  flex-direction: column;
  display: flex;
}

.article-card .article-card__super-title {
  color: var(--text-subtle);
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.article-card .article-card__title {
  color: var(--text-default);
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.article-card--type-1 {
  gap: var(--spacing-16);
  flex-direction: row;
}

.article-card--type-1 .article-card__text {
  gap: var(--spacing-4);
  width: 60%;
}

.article-card--type-1 .article-card__image {
  width: 40%;
}

@media screen and (min-width: 1080px) {
  .article-card--type-1 .article-card__super-title .typography {
    font-family: var(--font-family-default);
    font-size: var(--font-size-16);
    font-style: normal;
    font-weight: var(--font-weight-black);
    letter-spacing: .16px;
    line-height: 125%;
  }

  .article-card--type-1 .article-card__title {
    gap: var(--spacing-6);
  }

  .article-card--type-1 .article-card__title .typography {
    font-family: var(--font-family-default);
    font-size: var(--font-size-20);
    font-style: normal;
    font-weight: var(--font-weight-black);
    letter-spacing: .2px;
    line-height: 125%;
  }
}

.article-card--type-2, .article-card--type-3 {
  gap: var(--spacing-12);
}

.article-card:hover, .article-card:hover .article-card__super-title {
  text-decoration: none;
}

.article-card:hover .article-card__title {
  color: var(--text-default);
  text-underline-offset: 4px;
  text-decoration: underline;
}

.article-card:hover .article-card__image img {
  transform: scale(1.2);
}

.widget-special {
  background: var(--background-tile-layer-1);
  box-shadow: var(--shadow-lg);
  padding: var(--spacing-24);
  align-items: left;
  gap: var(--spacing-24);
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  display: flex;
}

@media screen and (max-width: 767px) {
  .widget-special {
    box-shadow: none;
    width: calc(100% - 30px);
    margin: 0 auto;
  }
}

@media screen and (min-width: 1080px) {
  .widget-special {
    padding: var(--spacing-32);
    box-shadow: var(--shadow-lg);
  }
}

.widget-special .widget-special__title h2, .widget-special .widget-special__title h3 {
  margin: 0;
  padding: 0;
}

.widget-special .widget-special__title h3 {
  color: var(--text-brand);
}

.widget-special .widget-special__perex .typography p:first-child, .widget-special .widget-special__perex .typography p:last-child {
  margin: 0;
}

.widget-special .widget-special__articles {
  gap: var(--spacing-24);
  grid-template-columns: 1fr;
  align-self: stretch;
  display: grid;
}

@media screen and (min-width: 1080px) {
  .widget-special .widget-special__articles {
    grid-template-columns: 1fr 1fr;
  }
}

.widget-special .widget-special__button .button-2024 {
  width: -webkit-fill-available;
}

.widget-special .widget-special__button .typography {
  margin: 0 auto;
}

.content-expander {
  width: 100%;
  transition: height .3s;
  animation: .3s slide-down;
  position: relative;
  overflow: hidden;
}

.content-expander:has(.content-expander__bottom) {
  padding-bottom: 84px;
}

.content-expander svg {
  transition: transform .3s;
  transform: rotate(180deg);
}

.content-expander--closed {
  box-sizing: border-box;
  max-height: 140px;
  padding-bottom: 0;
  overflow: hidden;
}

:has(.content-expander--closed__bottom) {
  padding-bottom: 0;
}

.content-expander--closed svg {
  transform: rotate(0);
}

.content-expander__bottom {
  z-index: 1;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.content-expander__gradient {
  background: linear-gradient(0deg, #f1f1f3 0%, rgba(241, 241, 243, 0) 100%);
  height: 56px;
}

.content-expander__button {
  background: var(--background-tile-layer-1);
}

.content-expander .typography p:first-of-type {
  margin-top: 0;
}

.gifts-ai {
  padding: var(--spacing-24);
  max-width: 620px;
}

.gifts-ai h1, .gifts-ai h2 {
  margin: 0;
}

@media screen and (min-width: 1080px) {
  .gifts-ai {
    padding: initial;
  }

  .gifts-ai ol {
    all: unset;
  }
}

.gifts-ai .gifts-ai__intro {
  gap: var(--spacing-24);
  margin-top: var(--spacing-24);
  flex-direction: column;
  display: flex;
}

.gifts-ai .gifts-ai__how-it-works {
  gap: var(--spacing-24);
  flex-direction: column;
  display: flex;
}

.gifts-ai .gifts-ai__steps {
  font-size: var(--font-size-18);
  margin: 0;
  list-style: decimal;
}

.gifts-ai .gifts-ai__form {
  box-sizing: border-box;
  background: var(--background-tile-layer-1);
  padding: var(--spacing-32) var(--spacing-24);
  align-items: flex-start;
  gap: var(--spacing-24);
  margin: var(--spacing-48) 0;
  flex-direction: column;
  width: 100%;
  display: flex;
}

@media screen and (max-width: 767px) {
  .gifts-ai .gifts-ai__form {
    padding: var(--spacing-24);
    box-shadow: none;
  }
}

@media screen and (min-width: 1080px) {
  .gifts-ai .gifts-ai__form {
    padding: var(--spacing-32);
    box-shadow: var(--shadow-lg);
  }
}

.gifts-ai .gifts-ai__form button {
  justify-content: center;
  width: 100%;
}

.gifts-ai .gifts-ai__results-success {
  margin-top: var(--spacing-24);
  gap: var(--spacing-16);
  flex-direction: column;
  display: none;
}

.gifts-ai .gifts-ai__results-success-content {
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

.gifts-ai .gifts-ai__result-section {
  gap: var(--spacing-8);
  flex-direction: column;
  display: flex;
}

.gifts-ai .gifts-ai__result-section ul {
  margin-left: var(--spacing-24);
  list-style: disc;
}

.gifts-ai .gifts-ai__result-section ul li {
  padding: var(--spacing-4) 0 var(--spacing-4) var(--spacing-6);
}

.gifts-ai .gifts-ai__result-section ul li::marker {
  padding: 10px;
}

.category-header {
  background: var(--background-tile-layer-1);
  box-sizing: border-box;
  flex-direction: column;
  gap: 0;
  width: 100%;
  display: flex;
}

@media screen and (min-width: 1080px) {
  .category-header--used-in-old-design-base {
    width: calc(100% + 120px);
    margin-bottom: 48px;
    margin-left: -60px;
    position: relative;
  }
}

.category-header .category-header__image {
  padding: var(--spacing-12) var(--spacing-24);
  background: linear-gradient(-45deg, #f66533, #fa3d4a, #5c7cff, #2af85e) 0 0 / 400% 400%;
  height: 160px;
  animation: 15s infinite gradient;
  position: relative;
  overflow: hidden;
}

.category-header .category-header__image img {
  width: auto;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 1080px) {
  .category-header .category-header__image img {
    width: 100%;
    max-width: none;
    height: auto;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 1080px) {
  .category-header .category-header__image {
    padding: var(--spacing-24) 52px;
    height: 240px;
  }
}

.category-header .category-header__image .typography {
  color: var(--text-white-default);
  z-index: 2;
  bottom: var(--spacing-12);
  text-shadow: var(--shadow-text);
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: calc(100% - 48px);
  margin: 0;
  display: -webkit-box;
  position: absolute;
  overflow: hidden;
}

@media screen and (min-width: 1080px) {
  .category-header .category-header__image .typography {
    bottom: var(--spacing-24);
    max-width: calc(100% - 104px);
  }
}

.category-header .category-header__image:before {
  content: "";
  z-index: 1;
  background: linear-gradient(rgba(15, 23, 31, 0) 40%, rgba(15, 23, 31, .8) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.category-header .category-header__menu {
  padding: var(--spacing-6) var(--spacing-12);
}

@media screen and (min-width: 1080px) {
  .category-header .category-header__menu {
    padding: var(--spacing-6) 52px;
  }
}

.category-header .category-header__menu .category-header__menu-inner {
  width: 100%;
  margin-left: -6px;
  padding: 0;
}

@media screen and (min-width: 1080px) {
  .category-header .category-header__menu .category-header__menu-inner {
    margin-left: -20px;
  }
}

.category-header .category-header__menu [data-swiper] .swiper__items {
  padding: var(--spacing-4) 12px;
  flex-direction: row;
  margin-left: -8px;
}

.category-header .category-header__menu [data-swiper] .swiper__arrow-gradient--left {
  background: linear-gradient(90deg, var(--background-tile-layer-1) 50%, rgba(227, 6, 19, 0) 100%);
}

.category-header .category-header__menu [data-swiper] .swiper__arrow-gradient--right {
  background: linear-gradient(270deg, var(--background-tile-layer-1) 50%, rgba(227, 6, 19, 0) 100%);
}

.category-header .category-header__menu [data-swiper] .swiper__arrow--left {
  left: 0;
}

.category-header .category-header__menu [data-swiper] .swiper__arrow--right {
  right: 0;
}

.category-header .category-header__description {
  padding: var(--spacing-12) var(--spacing-24) var(--spacing-24) var(--spacing-24);
}

@media screen and (min-width: 1080px) {
  .category-header .category-header__description {
    padding: var(--spacing-12) 52px var(--spacing-24) 52px;
  }
}

.category-header .category-header__description .content-expander {
  max-width: 640px;
}

.footer-link-list {
  gap: var(--spacing-24);
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

@media (min-width: 1080px) {
  .footer-link-list {
    gap: var(--spacing-32);
    flex-direction: row;
  }
}

.footer-link-list__title {
  cursor: pointer;
  color: var(--text-default);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (min-width: 1080px) {
  .footer-link-list__title {
    cursor: default;
  }
}

.footer-link-list__content {
  animation: .3s vanish;
  display: none;
  overflow: hidden;
}

.footer-link-list__item--collapsed .footer-link-list__content {
  height: auto;
  animation: 1s appear;
  display: flex;
}

@media (min-width: 1080px) {
  .footer-link-list__content {
    height: auto;
    animation: 1s appear;
    display: flex;
  }
}

.footer-link-list__item {
  gap: var(--spacing-12);
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
}

.footer-link-list__title-icon {
  transition: transform .3s ease-in-out;
}

.footer-link-list__title-icon--rotate {
  transition: transform .3s ease-in-out;
  transform: rotate(180deg);
}

@media (min-width: 1080px) {
  .footer-link-list__title-icon {
    display: none;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes vanish {
  from {
    opacity: 1;
    display: flex;
  }

  to {
    opacity: 0;
    display: none;
  }
}

.info-strip {
  padding: var(--spacing-4) var(--spacing-12);
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-0);
  border-radius: var(--radius-0);
  border-bottom: 1px solid var(--border-white-subtlest);
  background: var(--background-navigation-default);
  color: var(--text-inverse);
  box-sizing: border-box;
  flex-direction: row;
  height: 24px;
  transition: margin-top .3s ease-in-out;
  display: flex;
}

.info-strip--animate-up {
  margin-top: -32px;
}

@media (min-width: 768px) {
  .info-strip {
    justify-content: flex-start;
  }
}

.info-strip .info-strip__date--desktop {
  display: none;
}

@media (min-width: 768px) {
  .info-strip .info-strip__date--desktop {
    display: block;
  }
}

.info-strip .info-strip__date--mobile {
  display: block;
}

@media (min-width: 768px) {
  .info-strip .info-strip__date--mobile {
    display: none;
  }
}

.info-strip .info-strip__divider {
  align-items: center;
  gap: var(--spacing-10);
  background: var(--border-white-default);
  width: 1px;
  height: 1px;
  display: flex;
}

.info-strip .info-strip__divider--mobile-hidden {
  margin: var(--spacing-6);
  display: none;
}

@media (min-width: 768px) {
  .info-strip .info-strip__divider--mobile-hidden {
    display: block;
  }
}

.info-strip .info-strip__left {
  gap: var(--spacing-6);
  align-items: center;
  display: inline-flex;
}

.info-strip .info-strip__name-day {
  max-width: 200px;
}

@media only screen and (max-width: 320px) {
  .info-strip .info-strip__name-day {
    max-width: 160px;
  }
}

.info-strip .info-strip__name-day .tooltip__info-strip-date-trigger {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-top: 7px;
  display: inline-block;
  overflow: hidden;
}

@media only screen and (max-width: 320px) {
  .info-strip .info-strip__name-day .tooltip__info-strip-date-trigger {
    max-width: 160px;
  }
}

.info-strip .info-strip__name-day .tooltip__info-strip-date-trigger:before {
  content: "";
  vertical-align: middle;
  background: linear-gradient(270deg, #e30613 25%, rgba(227, 6, 19, 0) 100%) center no-repeat;
  width: 32px;
  height: 16px;
  margin-left: 0;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.info-strip .info-strip__name-day .tooltip__info-strip-date-trigger:after {
  content: "";
  vertical-align: middle;
  background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20viewbox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22m12%2015.4-6-6L7.4%208l4.6%204.6L16.6%208%2018%209.4z%22%2F%3E%3C%2Fsvg%3E") center no-repeat;
  width: 8px;
  height: 8px;
  margin-left: 0;
  display: block;
  position: absolute;
  top: 2px;
  right: 0;
}

.info-strip .info-strip__name-day .tooltip {
  max-width: 250px;
}

.info-strip .info-strip__weather {
  gap: var(--spacing-6);
  align-items: center;
  display: block;
}

.info-strip .info-strip__right-desktop-dropdown {
  display: none;
}

@media (min-width: 768px) {
  .info-strip .info-strip__right-desktop-dropdown {
    gap: var(--spacing-6);
    margin-left: auto;
    display: inline-flex;
  }
}

.navigation-2024 {
  background: var(--background-navigation-default);
  z-index: 9999;
  width: 100%;
  transition: margin-top .3s ease-in-out, margin-bottom .3s ease-in-out, display .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.navigation-2024 .logo-2024 {
  height: 32px;
}

.navigation-2024 .logo-2024 svg {
  width: auto;
  height: 100%;
}

.navigation__wrapper {
  background: var(--background-navigation-default);
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-4);
  box-sizing: border-box;
  z-index: 200;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: -webkit-fit-content;
  height: fit-content;
  display: flex;
}

@media (min-width: 768px) {
  .navigation__wrapper {
    padding: var(--spacing-12);
    height: 60px;
  }
}

.navigation__center, .navigation__left, .navigation__right {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  display: flex;
}

.navigation__center a {
  margin: 0 auto;
}

.navigation__right {
  justify-content: flex-end;
  gap: 0;
}

.navigation__right a {
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
}

.navigation__right a .avatar {
  cursor: pointer;
}

.navigation__hot-links {
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  display: none;
}

@media (min-width: 768px) {
  .navigation__hot-links {
    display: flex;
  }
}

.navigation__login-button--mobile {
  display: inline-block;
}

@media (min-width: 768px) {
  .navigation__login-button--mobile {
    display: none;
  }
}

.navigation__login-button--desktop {
  display: none;
}

@media (min-width: 768px) {
  .navigation__login-button--desktop {
    display: inline-block;
  }
}

#cnc_branding_creative_wrapper.cnc-branding .cnc-ads--leaderboard iframe:first-child, #cnc_branding_creative_wrapper.cnc-branding .cnc-ads--leaderboard:before {
  transition: top .3s ease-in-out;
}

.body--navigation {
  overscroll-behavior: none;
}

.body--navigation #cnc_branding_creative_wrapper.cnc-branding .cnc-ads--leaderboard iframe:first-child {
  top: 60px;
}

.body--navigation #cnc_branding_creative_wrapper.cnc-branding .cnc-ads--leaderboard:before {
  top: 70px;
}

.body--navigation-with-info-strip #cnc_branding_creative_wrapper.cnc-branding .cnc-ads--leaderboard iframe:first-child {
  top: 84px;
}

.body--navigation-with-info-strip #cnc_branding_creative_wrapper.cnc-branding .cnc-ads--leaderboard:before {
  top: 95px;
}

.body--navigation-with-dynamic-navigation #cnc_branding_creative_wrapper.cnc-branding .cnc-ads--leaderboard iframe:first-child {
  top: 99px;
}

.body--navigation-with-dynamic-navigation #cnc_branding_creative_wrapper.cnc-branding .cnc-ads--leaderboard:before {
  top: 111px;
}

.dynamic-navigation {
  background: var(--background-dynamicnavigation-default);
  z-index: 9000;
  box-sizing: border-box;
  overscroll-behavior: none;
  width: 100%;
  max-height: 40px;
  padding: 0;
  transition: all .3s ease-in-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
}

.dynamic-navigation:before {
  content: "";
  background: var(--background-dynamicnavigation-default);
  width: 100%;
  height: 100px;
  position: absolute;
  top: -100px;
}

@media screen and (min-width: 1080px) {
  .dynamic-navigation {
    overflow: hidden;
  }

  .dynamic-navigation:before {
    content: "";
    background: var(--background-dynamicnavigation-default);
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
  }
}

.dynamic-navigation [data-swiper] {
  padding: 0;
  padding-right: var(--spacing-4);
  max-width: 1080px;
  margin: 0 auto;
}

.dynamic-navigation [data-swiper] .swiper__items {
  gap: var(--spacing-6);
  padding: var(--spacing-4) var(--spacing-12);
}

.dynamic-navigation [data-swiper] .swiper__arrow-gradient--left {
  background: linear-gradient(90deg, #e30613 50%, rgba(227, 6, 19, 0) 100%);
  left: 0;
}

.dynamic-navigation [data-swiper] .swiper__arrow-gradient--right {
  background: linear-gradient(270deg, #e30613 50%, rgba(227, 6, 19, 0) 100%);
  right: 0;
}

.dynamic-navigation [data-swiper] .swiper__arrow--left {
  left: var(--spacing-12);
}

.dynamic-navigation [data-swiper] .swiper__arrow--right {
  right: var(--spacing-12);
}

.dynamic-navigation .button-2024 .typography {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 220px;
  overflow: hidden;
}

[data-dynamic-navigation-bg] {
  background: var(--background-dynamicnavigation-default);
  z-index: 100;
  width: 100%;
  height: 1px;
  transition: top .2s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.breaking-news {
  z-index: 1000;
  padding: var(--spacing-16);
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-12);
  background: var(--background-breakingnews-default);
  box-shadow: var(--shadow-md);
  overscroll-behavior: none;
  border-radius: 0;
  flex-direction: row;
  width: auto;
  margin: 0 auto;
  transition: all .3s ease-in-out;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@media screen and (min-width: 1080px) {
  .breaking-news {
    margin-top: 48px;
    margin-bottom: 20px;
  }
}

.breaking-news__wrapper {
  z-index: 1000;
  padding: 0 var(--spacing-24);
  transition: all .3s ease-in-out;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.breaking-news__content, .breaking-news__content-text, .breaking-news__content a {
  gap: var(--spacing-12);
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.breaking-news__content:hover, .breaking-news__content-text:hover, .breaking-news__content a:hover {
  text-decoration: none;
}

@media screen and (min-width: 1080px) {
  .breaking-news__content, .breaking-news__content-text, .breaking-news__content a {
    align-items: center;
  }
}

@media (orientation: landscape) {
  .breaking-news__image {
    max-width: 200px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1079px) {
  .breaking-news__image {
    width: 300px;
    min-width: 300px;
  }
}

@media screen and (min-width: 1080px) {
  .breaking-news__image {
    width: 564px;
    min-width: 564px;
  }
}

@media (orientation: landscape) {
  .breaking-news__content, .breaking-news__content a {
    flex-direction: row;
  }
}

@media screen and (min-width: 768px) and (max-width: 1079px) {
  .breaking-news__content, .breaking-news__content a {
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (min-width: 1080px) {
  .breaking-news__content, .breaking-news__content a {
    flex-direction: row;
  }
}

@media screen and (min-width: 1080px) {
  .breaking-news__content-text {
    align-items: flex-start;
  }
}

.breaking-news__title .typography {
  color: var(--text-default);
  text-decoration: none;
}

.breaking-news__close {
  border-radius: var(--radius-6);
  background: var(--background-button-secondary-default);
  width: 36px;
  height: 36px;
  position: absolute;
  top: -18px;
  right: -18px;
}

.atomic-design {
  z-index: 50;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.atomic-design__items {
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px;
  display: flex;
}

.atomic-design__items--elements {
  background-color: #f08080;
}

.atomic-design__items--components {
  background-color: #9acd32;
}

.atomic-design__items--boxes {
  background-color: #ff0;
}

.atomic-design__item {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px;
  display: flex;
  position: relative;
}

.atomic-design__item h3, .atomic-design__item .atomic-design__item-toggle-btn {
  cursor: pointer;
}

.atomic-design__item--visible .atomic-design__item-example, .atomic-design__item--visible .atomic-design__item-example-code {
  display: block;
}

.atomic-design__item-btns {
  position: absolute;
  top: 0;
  right: 0;
}

.atomic-design__item-btns button {
  color: #fff;
  cursor: pointer;
  background: #000;
  border: 0;
  padding: 5px;
}

.atomic-design__item-example {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 10px;
  display: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, .1);
}

.atomic-design__item-example hr {
  border: 1px solid #000;
}

.atomic-design__item-example-code {
  color: #adff2f;
  white-space: pre;
  background-color: #000;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px 10px;
  display: none;
  overflow: scroll;
}

:root {
  --footer-side-padding-desktop: 52px;
}

.footer-2024 {
  padding: var(--spacing-36) var(--spacing-24);
  gap: var(--spacing-32);
  color: var(--text-default);
  box-sizing: border-box;
  background-color: #fff;
  border-top: none;
  flex-direction: column;
  width: calc(100% - 48px);
  display: flex;
  position: relative;
}

.footer-2024:before {
  content: "";
  background: var(--border-brand);
  width: 100%;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .footer-2024 {
    padding: var(--spacing-36) 52px;
    width: 100%;
  }
}

.footer-2024 .link-list {
  margin: 0;
  padding: 0;
}

.footer-2024 hr {
  border-top: 1px solid var(--border-subtle);
  height: 0;
  margin: 0;
}

.footer__top {
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .footer__top {
    gap: var(--spacing-24);
    flex-direction: row;
  }

  .footer__top .buttons-store {
    position: absolute;
    right: 0;
  }
}

.footer__interests {
  flex-direction: row;
  justify-content: center;
}

@media (min-width: 768px) {
  .footer__interests {
    flex-direction: column;
    justify-content: space-between;
  }
}

.footer__bottom {
  gap: var(--spacing-24);
  flex-direction: column;
  display: flex;
}

